<template>
  <AppLayout>
    <ViewWrapper
      title="Integrations"
      :tabs="tabs"
      :breadcrumbs="breadcrumbs"
      @quickHelp="showQuickHelp"
    >
      <!-- quick help -->

      <QuickHelp v-model="quickHelp" />

      <!-- ... -->

      <!-- child routes -->

      <router-view :key="$route.fullPath" />

      <!-- ... -->
    </ViewWrapper>
  </AppLayout>
</template>

<script>
import AppLayout from "@/layouts/app/AppLayout.vue";
import ViewWrapper from "@/components/common/view-wrapper/ViewWrapper.vue";
import QuickHelp from "@/components/common/QuickHelp.vue";

export default {
  name: "Integration",

  components: { AppLayout, ViewWrapper, QuickHelp },

  data() {
    return {
      breadcrumbs: [
        {
          id: this.$nano.id(),
          label: "dashboard",
          route: "dashboard",
        },
        {
          id: this.$nano.id(),
          label: "settings",
          route: "settings",
        },
        {
          id: this.$nano.id(),
          label: "integration",
          route: "settings",
        },
        {
          id: this.$nano.id(),
          label: "",
          route: "",
        },
      ],
      tabs: [
        // {
        //   id: this.$nano.id(),
        //   label: "overview",
        //   icon: "mdi-view-dashboard-outline",
        //   route: "integration-overview",
        // },
        {
          id: this.$nano.id(),
          label: "manage",
          icon: "eva-settings-outline",
          route: "integration-browse",
        },
      ],
      quickHelp: false,
    };
  },

  watch: {
    $route: {
      immediate: true,
      deep: true,
      handler() {
        const index = this.breadcrumbs.length - 1;
        this.breadcrumbs[index].label = this.$route.meta.breadcrumb;
      },
    },
  },

  methods: {
    showQuickHelp() {
      this.quickHelp = true;
    },
  },
};
</script>

<style lang="scss" scoped></style>
